<template>
<div style="padding:10px;">
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width='110px' style="margin-bottom:80px;" v-loading='loading'>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>内容详情</span>
      </div>
      
      <el-form-item label="类型：" prop="InformationType" >
				<el-select v-model="ruleForm.InformationType" placeholder="请选择" :disabled='currentEditId!= null' @change="ruleForm.Describe=''">
					<el-option v-for="item in typeOptions"	:key="item.value" :label="item.label"	:value="item.value">
					</el-option>
				</el-select>
      </el-form-item>
      
      <el-form-item label="标题：" prop="Title">
        <el-input v-model="ruleForm.Title" style="width:500px" maxlength="50" placeholder="最多输入50个字"></el-input>
      </el-form-item>
      
      <el-form-item label="分组：" >
				<!-- <el-select v-model="ruleForm.InformationGroupId" placeholder="请选择">
					<el-option v-for="item in groupOptions"	:key="item.Id" :label="item.GroupName"	:value="item.Id">
					</el-option>
				</el-select> -->
				<el-cascader v-model="ruleForm.InformationGroupId" :options="groupOptions" style="width:220px" filterable
          :props="{value:'Id',label:'GroupName',children:'LowerList'}"></el-cascader>
      </el-form-item>
      
      <el-form-item label="序号：" >
        <el-input v-model="ruleForm.Sort" style="width:100px" ></el-input>
      </el-form-item>
      
      <el-form-item label="是否显示：" prop="IsDisplay">
        <el-radio-group v-model="ruleForm.IsDisplay" >
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="封面图：">
				<div class="flexRow">
					<el-upload :action="imgApi" accept="image/*" list-type="picture-card" :show-file-list="false"
					:before-upload="beforeupload" :on-success="($event)=>{onsuccess($event,'CoverImgUrl')}">
						<div style="width:100%;overflow-y:hidden;height:100%" v-if="ruleForm.CoverImgUrl">
							<img :src="imgUrl+ruleForm.CoverImgUrl" style="width:100%" alt="">
						</div>
						<i class="el-icon-plus" v-else></i>
					</el-upload>
					<div style="margin-left:10px">
						<div class="coverTips">
							<div>
								1.封面图片建议宽750px，高420px
							</div>
							<div>
								2.图片大小1M以内
							</div>
						</div>
						<div style="margin-top:60px;height:30px">
							<el-button type="text" v-if="ruleForm.CoverImgUrl" @click="ruleForm.CoverImgUrl=''">清空</el-button>
						</div>
					</div>
				</div>
      </el-form-item>
      
      <el-form-item label="图片：" prop="ImgUrls" v-if="ruleForm.InformationType==1">
        <div class="filter-item" style="vertical-align: top;">
          <upload-img  @transPicture="transPictureFun" :AttachedImgUrls="ruleForm.ImgUrls" :Type="2"></upload-img>
          <div class="textColor" style="margin-top:20px">拖拽图片可以调整顺序，每张图片大小请控制到2M以内；图片最多上传9张</div>
        </div>
      </el-form-item>

      <el-form-item label="视频："  prop="Video" v-if="ruleForm.InformationType==2">
					<el-button type="text" style="color: #F56C6C;" @click="ruleForm.Video = ''">删除视频</el-button>
					<br>
          <el-upload   
          class="avatar-uploader" 
          :action="imgApi" 
          :show-file-list="false" 
					 accept=".mp4"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          list-type="picture-card" style="display: inline-block;marin-left:10px;">
						<video v-if="ruleForm.Video" :src="imgUrl+ruleForm.Video" class="avatar" controls="controls" style="width:148px;height:148px"></video>
            <i v-else class="el-icon-plus"></i>
          </el-upload>
					<div class="textColor" style="margin-top:20px">建议使用16：9比例视频；视频大小请控制在30M以内</div>
      </el-form-item>

      <el-form-item label="图文："  prop="Describe" v-if="ruleForm.InformationType==3">
				<div style="margin-bottom:100px;">
					<quill-editor ref="myTextEditor" v-model="ruleForm.Describe" :options="quillOption"
						style="margin-top:10px;margin-left:5px;width:750px;height: 400px;max-height:720px;">
					</quill-editor>
					
				</div>
      </el-form-item>

      <el-form-item label="描述：" prop="Describe" v-else>
				<el-input type="textarea" style="width: 700px" :rows="4" v-model="ruleForm.Describe" maxlength="1000" show-word-limit></el-input>      
      </el-form-item>

			<el-form-item label="商品：" >
				<div class = "flexRow">
					<el-button type="text" @click="addProductDialogShow=true">选择商品</el-button>
					<span class = "grayfont" style="margin-left:10px">最多可选择20个商品；拖拽商品图片可排序</span>
				</div>
        <div>
					<draggable  :list="ruleForm.RelatedAdvertiseProductToSave" :options="{animation: 100, handle:'.shopbox'}"
						class = "flexRow" style="margin:10px 0px 0px 0px;flex-wrap:wrap;">
						<div class = "shopbox" v-for="(v,i) in ruleForm.RelatedAdvertiseProductToSave" :key="i">
							<div class = "imgbox">
								<div class = "position-img" >
									<img :src="imgUrl+v.ImgUrl" style="width:100%;height:100%" alt="">
									<div class = "icon-close" @click="removeshop(i)">
										<i class = "el-icon-close"></i>
									</div>
								</div>
							</div>
							<div style="flex:1;padding:5px" class = "fontbox">
								<el-tooltip popper-class="itemtooltip" effect="light" :content="v.Name" placement="top">
									<div class = "fonthidden2">{{v.Name}}</div>
								</el-tooltip>
							</div>
						</div>
					</draggable>
				</div>
      </el-form-item>
    </el-card>
  </el-form>
  
    <!-- 底部保存 -->
    <div class="bottom-save-btn">
      <el-button style="width:240px" @click="canselGoods">取消</el-button>
      <el-button type="primary" style="width:240px" @click="submitForm('ruleForm')" :loading="loading">保存</el-button>
    </div>

		<!-- 选择商品 -->
		<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1300px" class="dialog">
			<selectshop :params="['KeyWord', 'ProductType', 'ProductGroupIds', 'ProductBrandId']" :selectedData="ruleForm.RelatedAdvertiseProductToSave"
			 @getSelectproList="getSelectproList" :goodsMaxlength='20' :visible.sync="addProductDialogShow" v-if="addProductDialogShow">
			</selectshop>
		</el-dialog>


</div>
</template>

<script>
  import config from '@/config/index';
	import uploadImg from '@/components/uploadImgs/uploadPicture';
	import selectshop from '@/views/goods/selectgoodsElement.vue'

	import draggable from "vuedraggable";
	import quillConfig from '@/components/quill-config1.js'
	
	import {
		quillEditor
	} from 'vue-quill-editor'
	
	import {
		informationedit,
		informationdetail,
		informationgrouppageinit
	} from '@/api/goods.js'
	import {
		productAttributeproductAttributeTree,
		informationinformationGroupTree
	} from "@/api/TurnTomySelf"
  export default {
      components: {
        uploadImg,
				selectshop,
				draggable,
				quillEditor
      },
    data () {
			var validateDescribe = (rule, value, callback) => {
				if(!value){
					if(this.ruleForm.InformationType==3){
						callback(new Error('请输入内容详情'))
					}else{
						callback(new Error('请输入描述内容'))
					}
				}else{
					callback()
				}
      };
      return {
        imgApi: config.UPLOAD_IMG,
        imgUrl: config.IMG_BASE,
        loading:false,
        
				typeOptions:[
					{
						label:'图片',
						value:1,
					},
					{
						label:'视频',
						value:2
					},
					{
						label:'图文',
						value:3
					}
				],
				groupOptions: [],
        ruleForm:{
					Title: '',
					InformationType: 1,
					ImgUrls: [],
					Video: '',
					InformationGroupId: [],
					Sort: 0,
					AddTime: '',
					IsDisplay: true,
					Describe: '',
					RelatedAdvertiseProductIdList:[],
					RelatedAdvertiseProductToSave:[],
					CoverImgUrl:'',
        },
				quillOption:quillConfig,
				rules: {
					Title: [{
							required: true,
							message: '请输入标题',
							trigger: 'blur'
						}
					],
					Sort: {
						required: true,
						message: '请输入序号',
						trigger: 'blur'
					},
					InformationType: {
						required: true,
						message: '请选择类型',
						trigger: 'change'
					},
					InformationGroupId: {
						required: true,
						message: '请选择分组',
						trigger: 'change'
					},
					IsDisplay: {
						required: true,
						message: '请选择是否显示',
						trigger: 'change'
					},
					ImgUrls: {
						required: true,
						message: '请选择图片',
						trigger: 'change'
					},
					Video: {
						required: true,
						message: '请选择视频',
						trigger: 'change'
					},
					Describe:{required: true, validator: validateDescribe, trigger: 'blur' }
				},
				currentEditId: null,

				addProductDialogShow:false,
      }
    },
    created () {
      this.currentEditId = this.$route.query.id
			this.getshop().then(()=> {
				if(this.currentEditId){
					this.getInfo()
				}
			})
    },
    methods: {
			onsuccess(file,key){
				// console.log(file,key)
				this.ruleForm[key] = file[0]
			},
			beforeupload(file){
				const size = file.size / 1024 / 1024 < 1;
				const testtype = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg']
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!size) {
					this.$message.error('图片大小请控制在1M以内');
					return false
				}
			},
			removeshop(i){
				this.ruleForm.RelatedAdvertiseProductToSave.splice(i,1)
			},
			getSelectproList(list){
				this.ruleForm.RelatedAdvertiseProductToSave = list
				this.ruleForm.RelatedAdvertiseProductIdList = list.map((v)=>{
					return v.ProductId
				})
				this.addProductDialogShow = false
			},
				// 获取列表数据
			async getshop(){
				try{
					let res = await informationinformationGroupTree({
						Keywords:'',
						Skip:0,
						Take:99999
					})
					if(res.IsSuccess){
						this.groupOptions = res.Result.map(v=>{
							if(!v.LowerList||!v.LowerList.length){
								delete v.LowerList
							}
							return v
						})
						// console.log(this.groupOptions)
					}
				}finally{
					//
				}
			},
      async getInfo(){
        try{
          this.loading = true
          let result = await informationdetail({
            Id: this.currentEditId
          })
          if(result.IsSuccess){
            this.ruleForm = JSON.parse(JSON.stringify(result.Result))
						if(!this.ruleForm.InformationGroupId){
							this.ruleForm.InformationGroupId = []
						}else{
							if(this.ruleForm.ParentGroupId){
								this.ruleForm.InformationGroupId = [this.ruleForm.ParentGroupId,this.ruleForm.InformationGroupId]
							}else{
								this.ruleForm.InformationGroupId = [this.ruleForm.InformationGroupId]
							}
						}
          }
        } catch(e){
          console.log('e',e)
          this.loading = false
        } finally{
          this.loading = false
        }
      },
			// 批量上传的图片
			transPictureFun(datas) {
				this.ruleForm.ImgUrls = datas;
			},
      
			// 上传规格图
			handleAvatarSuccess(res, file) {
				this.ruleForm.Video = res[0]
			},
			beforeAvatarUpload(file) {
				const isLt30M = file.size / 1024 / 1024 < 30;
				if (!isLt30M) {
					this.$message.error('视频大小应控制在30M以内');
				}
				return isLt30M;
			},
			// 取消操作
			canselGoods() {
        this.$router.push({
          path: '/market/message/manageList'
        });
			},
			// 保存商品信息
			submitForm(formName) {	
        this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true
						try {
							this.ruleForm.ImgUrls = this.ruleForm.ImgUrls.slice(0,9)
							let ruform = JSON.parse(JSON.stringify(this.ruleForm))
							let InformationGroupId = ruform.InformationGroupId
							console.log(ruform)
							if(InformationGroupId&&InformationGroupId.length){
								ruform.InformationGroupId = InformationGroupId[InformationGroupId.length-1]
							}
							ruform.RelatedAdvertiseProductIdList = this.ruleForm.RelatedAdvertiseProductToSave.map((v)=>{
								return v.ProductId
							})
							// console.log(ruform)
							let result = await informationedit(ruform);
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '保存成功'
								});
								this.$router.push({
									path: '/market/message/manageList'
								});
							}
						} catch (e) {
							this.loading = false
						} finally {
							setTimeout(() => {
								this.loading = false
							}, 600)
						}
					} else {
						this.$common.completeFormInformation('请完善信息', 'is-error')
						return false;
					}
				});
			},
    }
  }
</script>

<style lang="less" scoped>	
		.coverTips{
			font-size: 14px;
			color: #999999FF;
			line-height: 1.5;
		}
		.textColor {
			color: #909399;
			font-size: 12px;
		}
		.upload-avatar-video {
			width: 145px;
			height: 145px;
		}
		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}
		.flexRow{
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.shopbox{
			display: flex;
			flex-direction: column;
			/* border:1px solid black; */
			width:120px;height:170px;
			margin:0px 20px 10px 0px;
			.imgbox{
				width:120px;height:120px;
				/* border:1px solid red; */
				.position-img{
					position: relative;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					.icon-close{
						/* border:1px solid black; */
						width:16px;
						height: 16px;
						position: absolute;
						right:-8px;
						top:-8px;
						border-radius: 50%;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						color: white;
						background: black;
						font-size: 12px;
						cursor: pointer;
					}
				}
			}
			.fontbox{
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				.fonthidden2{
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					word-break: break-all;
					line-height: 1.5;
				}
			}
		}
		.grayfont{
			font-size: 12px;
			line-height: 1.5;
			color: #999999;
		}
</style>
<style>

		.itemtooltip{
			max-width:350px;
		}
</style>